import React, { useEffect } from 'react'
import Illustration from "./Illustration.svg"
import "./Error404.css"

export default function Error404() {
  useEffect(() => {
    document.title = "Page introuvable - Azoya SPA Migennes";
  }, [])
  return (
    <div className='Error404'>
      <h1>La page demandé n'existe pas !</h1>
      <img src={Illustration} alt="404-Illustration" />
    </div>
  )
}
