import React, { useEffect } from 'react'
import './PlanityGift.css'

export default function PlanityGift() {

    useEffect(() => {
        // A supprimer lors de la création d'une page dédiée.
        document.title = "Offrir - Azoya SPA";

        const container = document.getElementById('planityContainer');
        window.planity = {
            key: '-NtvFwRqK9PVb2r31f0I',
            primaryColor: '#87D9D9',
            options: {
                headerWidth: '100px'
            },
            giftVoucherContainer: container
        };

        const script = document.createElement('script');
        script.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/polyfills.latest.js";
        script.async = true;
        container.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js";
        script2.async = true;
        container.appendChild(script2);
        
    }, [])

    return (
        <div className='planity'>
            <div id='planityContainer'></div>
        </div>
    )
}
