import { React, useEffect, useState } from 'react';
import './ReserverEnLigne.css'
import Illustration from "./Illustration.svg"
import PlanityAppointment from '../../components/planity/Appointment/PlanityAppointment';

function ReserverEnLigne() {

  useEffect(() => {
    document.title = "Reserver en ligne - Azoya SPA Migennes";
  }, [])

  const [find, setFind] = useState(false);

  const activeFind = () => {
    const form = document.getElementById('form-choices');
    form.style.display = 'none';
    setFind(true);
  }

  const deactiveFind = () => {
    const form = document.getElementById('form-choices');
    form.style = undefined
    setFind(false);
  }

  // Whitelist state and methods
  const [whitelist, setWhitelist] = useState([]);

  const addToWhitelist = (id) => setWhitelist([...whitelist, ...id]);
  const removeFromWhitelist = (id) => setWhitelist(whitelist.filter(e => !id.includes(e)));

  const handleChoiceCheckbox = (e) => {
    e.target.checked ? addToWhitelist(e.target.value.split(';')) : removeFromWhitelist(e.target.value.split(';'))
  }

  const handleFindButton = (e) => {
    e.preventDefault();
    activeFind();
  }

  const handleResetButton = (e) => {
    e.preventDefault();
    deactiveFind();
  }


  return (
    <div className="ReserverEnLigne" id='ReserverEnLigne'>

      <div className="slide">
        <img src={Illustration} alt="Illustration" />
        <div className="text-content">
          <h1>Reservez <em>en ligne</em> via notre partenaire Planity</h1>
          <p>Pour réserver il vous suffit de commander via notre application planity intégrée plus bas. Vous serez ammené à verser un accompte de 20% pour vos réservations internet. Si vous le souhaitez vous pourrez également régler vos prestations directement à la réservation.</p>
        </div>
      </div>


      {/* <div className="documentation">
        <h1>Vous ne connaissez pas encore nos prestations ?</h1>
        <p>
          Vous pouvez nous contacter au <a href="tel:+33679333364">06 79 33 33 64</a> ou 
          sur <a href="mailto:contact@azoya-spa.com">contact@azoya-spa.com</a> et nous répondrons à 
          toutes vos questions. Nous prévoyons de réaliser une page dédié à la description de nos services.
        </p>
      </div> */}

      <form id='form-choices'>
        <div className="head">
          <h1>Qu'est-ce qui vous intéresse ?</h1>
          <p>
            Sélectionnez les catégories qui vous intéresse puis cliquez sur "Afficher ma sélection" pour choisir
            vos prestations.<br />
          </p>
        </div>

        <div className="choices">
          <div className="choice">
            <input
                type="checkbox"
                name="soins-corps"
                id="soins-corps"
                onChange={handleChoiceCheckbox}
                value="-NuclzkAJ2QZJBTyW6ge;-NuclgxGuzg44UVABrPr;-NuclVbSnpSdP7XOkvsH;-NuclMxMvRdnBPYXnZ8H;-NuclCSO7jrwSGIIDHg7"/>
            <label htmlFor="soins-corps">Soins Corps</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="pause-detente"
                id="pause-detente"
                onChange={handleChoiceCheckbox}
                value="-NuckyfOw8ZbAwRVvL9B;-Nuckas0by_zrfFVCwgt"/>
            <label htmlFor="pause-detente">Pause Détente</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="gommage-baija"
                id="gommage-baija"
                onChange={handleChoiceCheckbox}
                value="-NuckQQg-RDKV9AaQ1I9;-NuckEc9AV1pFL45icDI"/>
            <label htmlFor="gommage-baija">Gommage Baïja</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="soins-visage-baija"
                id="soins-visage-baija"
                onChange={handleChoiceCheckbox}
                value="-NucjaE1vhE3wN-sN7Ef;-NucjGWo3jPF20Am8ogW"/>
            <label htmlFor="soins-visage-baija">Soins Visage Baïja</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="epilations"
                id="epilations"
                onChange={handleChoiceCheckbox}
                value="-NuckAW8w2dQtfLR8RXC;-Nuck79qkH24Umz12CHL;-Nuck1QYCk9bZAzs0RqO;-NucjzF2TiDYo5YwjyaF;-NucjuD2hTvi0qnSF2XE;-NucjrkRwcRP5eWqMFsZ"/>
            <label htmlFor="epilations">Épilations</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="mains-pieds"
                id="mains-pieds"
                onChange={handleChoiceCheckbox}
                value="-Nucit29cARrenYJOlzJ;-Nucj5REPMeVxEOAI-VT"/>
            <label htmlFor="mains-pieds">Mains - Pieds</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="yumi"
                id="yumi"
                onChange={handleChoiceCheckbox}
                value="-NucikDDZneGiqpVP4Lb"/>
            <label htmlFor="yumi">Yumi</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="maquillage-des-ongles"
                id="maquillage-des-ongles"
                onChange={handleChoiceCheckbox}
                value="-Nucmiu_8MZip1jSSAc_;-NucmF4d7fAvlmuyEzA7"/>
            <label htmlFor="maquillage-des-ongles">Maquillage Des Ongles</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="balneo-dome-japonnais"
                id="balneo-dome-japonnais"
                onChange={handleChoiceCheckbox}
                value="-NucmyGUYcl4GaA2rFDk;-Nucn-1fWPI_aftUVJXV;-Nucn06H6Tysb8uZ1AcF"/>
            <label htmlFor="balneo-dome-japonnais">Balneo - Dome Japonnais</label>
          </div>

          <div className="choice">
            <input
                type="checkbox"
                name="spa-mini-kids"
                id="spa-mini-kids"
                onChange={handleChoiceCheckbox}
                value="-NvTI41nyS6uQlle7U6x"/>
            <label htmlFor="spa-mini-kids">SPA Mini Kids</label>
          </div>
        </div>
        <div className="buttons">
          <button onClick={handleFindButton}>Afficher ma sélection</button>
        </div>
      </form>

      {find &&
          <>
            <form id='form-reset'>
              <div className="buttons">
                <button onClick={handleResetButton}>Modifier ma selection</button>
              </div>
            </form>
          </>
      }

      <div className='call-to-gift'>
        Vous souhaitez offrir ? <a href='/offrir' target='_blank'>Cliquez ici</a>
      </div>

     {find && <PlanityAppointment whitelist={[...whitelist]}/>} 
    </div>
  );
}

export default ReserverEnLigne;