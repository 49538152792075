import React from 'react'
import "./TablePrice.css"

export default function TablePrice(props) {

  const prestationArray = props.service.prestationArray;

  return (
    <div className="TablePrice">
      <h1>Présentation du service <em>{props.service.title}</em></h1>
      <p>
        {props.service.resume.split("\n").map(line => {
          return (
            <>
              {line}
              <br />
            </>
          );
        })}
      </p>
      <div className="table">
        {prestationArray.map((row, index) => {
          let className = row.isTitle ? "title-row" : "white-row";
          
          return (
            <div key={index} className={"row " + className}>
              <div className="column1">
                <p>{row.column1}</p>
              </div>
              <div className="column2">
                <p>{row.column2}</p>
              </div>
              <div className="column3">
                <p>{row.column3}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}
